<script>
import vSelect from 'vue-select'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import idLocale from '@fullcalendar/core/locales/id' // Import bahasa Indonesia
import axios from '@/config/Axios';

import { BFormInput, BRow, BCol, BFormGroup, BFormSelect, BCard, BCardBody } from 'bootstrap-vue'
export default {
  components: {
    FullCalendar, vSelect, BFormInput, BRow, BCol, BFormGroup, BFormSelect, BCard, BCardBody

  },
  data: function () {
    return {
      alat_produksi: [],
      id_alat_produksi: '',
      periode: '',
      selected: {},
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        events: [
        ],
        locale: idLocale,
        dayHeaderFormat: { weekday: 'long' },
        eventClick: this.handleEventClick

      }
    }
  },
  mounted() {
    this.calender_list();
    this.get_alat_produksi();
  },
  methods: {
    handleEventClick(info) {
      // Display event details or perform actions
      // alert(`Event: ${info.event.title}\nStart: ${info.event.startStr}\nEnd: ${info.event.endStr}`);
      // You can also perform more complex actions here
      // For example, navigate to a different page, show a modal, etc.
    },
    async get_alat_produksi() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/jadwal/alat_produksi',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.alat_produksi = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async calender_list() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/admin/jadwal/calender_list',
        data: {
          periode: self.periode,
          id_alat_produksi: self.id_alat_produksi.id_alat_produksi

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.calendarOptions.events = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },

}
</script>

<template>
  <div>
    <b-card class="mt-1">
      <b-row>
        <b-col md="12">
          <h5 class="card-title"><i class="fa fa-list"></i> Jadwal Penggunaan Alat Produksi Bersama UMKM Sambirejo
            Semarang
          </h5>
          <b-form-group>
            <label><strong>Nama Alat Produksi</strong> </label>
            <v-select v-model="id_alat_produksi" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_alat"
              @input="calender_list()" placeholder="Silahkan Pilih Jenis Alat Produksi" :options="alat_produksi.alat" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="fullcalendar-wrapper">
            <!-- @event-click="handleEventClick"  -->
            <FullCalendar :options='calendarOptions' />
          </div>
        </b-col>
      </b-row>

    </b-card>

  </div>
</template>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
h1 {
  text-align: center;
  margin-bottom: 20px;
}

.fullcalendar-wrapper {
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  /* Make the width responsive */
}

.fc-toolbar-title {
  font-size: 1.5em;
  text-align: center;
}

.fc {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 100%;
  /* Make the calendar take the full width of its container */
}

.fc th {
  text-align: center;
  padding: 10px 0;
}

.fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fc-daygrid-day-number {
  margin: 0;
  font-size: 1em;
}

.fc-daygrid-day {
  padding: 5px;
}

.fc-event {
  font-size: 0.9em;
  margin-bottom: 5px;
  /* Adding margin between events */
}
</style>